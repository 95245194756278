<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-group name="Параметры TTS">
    <fl-select v-model="data.settings.tts_params.provider" label="Провайдер" :options="$options.tts.providers"/>
    <fl-select v-model="data.settings.tts_params.language" label="Язык" :options="$options.tts.langs[data.settings.tts_params.provider]"/>
    <div v-if="data.settings.tts_params.provider=='yandex'">
      <fl-select v-model="data.settings.tts_params.voice.id" label="Голос" :options="$options.tts.voices.yandex"/>
      <fl-select v-model="data.settings.tts_params.voice.emotion" label="Эмоция" :options="$options.tts.emos.yandex"/>
      <fl-select v-model.number="data.settings.tts_params.voice.speed" label="Скорость" :options="$options.tts.speed.yandex"/>
      <div class="d-flex gap-1">
        <input v-model.number="data.settings.tts_params.voice.speed" type="range" class="form-range" min="0.05" max="3" step="0.05">
        <small style="width: 12.5%">{{ data.settings.tts_params.voice.speed }}</small>
      </div>
    </div>
    <div v-if="data.settings.tts_params.provider=='tinkoff'">
      <fl-select v-model="data.settings.tts_params.voice.id" label="Голос" :options="$options.tts.voices.tinkoff"/>
    </div>
    <div v-if="data.settings.tts_params.provider=='speechpro'">
      <fl-select v-model="data.settings.tts_params.voice.id" label="Голос" :options="$options.tts.voices.speechpro"/>
    </div>
    <div v-if="data.settings.tts_params.provider=='salute'">
      <fl-select v-model="data.settings.tts_params.voice.id" label="Голос" :options="$options.tts.voices.salute"/>
    </div>
    <div v-if="data.settings.tts_params.provider=='true_google'">
      <fl-select v-model="data.settings.tts_params.voice.id" label="Голос" :options="$options.tts.voices.true_google"/>
      <fl-select v-model.number="data.settings.tts_params.voice.speed" label="Скорость" :options="$options.tts.speed.true_google"/>
    </div>
  </fl-group>
</template>

<script>
import FlSelect from "../components/FlSelect";
import FlGroup from "../components/FlGroup";
import FlInput from "@/pages/flow/forms/components/FlInput";

export default {
  components: {FlInput, FlGroup, FlSelect},
  props: ["data"],
  tts: {
    providers: {
      yandex: "Яндекс",
      tinkoff: "Тинькофф",
      speechpro: "Центр речевых технологий",
      salute: "Сбер Салют",
      true_google: "Google"
    },
    langs: {
      yandex: {
        'ru-RU': "Русский",
        'en-US': "Английский",
        'kk-KK': "Казахский",
      },
      tinkoff: {
        'ru-RU': "Русский",
        'en-US': "Английский",
      },
      speechpro: {
        'ru-RU': "Русский",
      },
      salute: {
        'ru-RU': "Русский",
      },
      true_google: {
        'en-US': "Английский",
        'ru-RU': "Русский",
      },
    },
    voices: {
      yandex: {
        alena: "Алена",
        filipp: "Филип",
        jane: "Джейн",
        omazh: "Омаж",
        zahar: "Захар",
        ermil: "Эмиль",
        amira: "Амира",
        john: "Джон",
      },
      tinkoff: {
        alyona: "Алена",
        dorofeev: "Дорофеев",
        maxim: "Максим",
        "alyona:sad": "Алена (грустное)",
        "alyona:funny": "Алена (веселое)",
        "alyona:flirt": "Алена (флирт)",
        "dorofeev:neutral": "Дорофеев (обычное)",
        "dorofeev:drama": "Дорофеев (драма)",
        "dorofeev:comedy": "Дорофеев (комедия)",
        "dorofeev:info": "Дорофеев (инфо)",
        "dorofeev:tragedy": "Дорофеев (трагедия)",
      },
      speechpro: {
        Dasha_8000_3_2_y: "Даша",
        Eliza_8000_3_2_y: "Элиза",
        Julia_8000_3_2_y: "Юлия",
        Vladimir_8000_3_2_y: "Владимир",
        Vladimir_8000n: "Владимир, новый",
      },
      salute: {
        Nec_8000: "Наталья",
        Bys_8000: "Борис",
        May_8000: "Марфа",
        Tur_8000: "Тарас",
        Ost_8000: "Александра",
        Pon_8000: "Сергей",
        Kin_8000: "Кира",
      },
      true_google: {
        "en-US-Studio-Q": "Studio-Q",
        default: "По умолчанию",
      },
    },
    emos: {
      yandex: {
        neutral: "Нейтральный",
        good: "Доброжелательный",
        evil: "Злой",
      }
    },
    speed: {
      yandex: {
        0.25: "Очень медленно",
        0.5: "Медленно",
        1: "Нормально",
        2: "Быстро",
        3: "Очень быстро",
      },
      true_google: {
        0.25: "0.25",
        0.5: "0.5",
        0.8: "0.8",
        0.85: "0.85",
        0.9: "0.9",
        0.95: "0.95",
        1: "1",
        1.05: "1.05",
        1.1: "1.1",
        1.15: "1.15",
        1.2: "1.2",
        1.25: "1.25",
        1.3: "1.3",
        2: "2",
        3: "3",
      }
    },
  }

}
</script>

